/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Header from "@components/Common/Header";
import verifiedIcon from "@images/verified_icon.png";
import envelopeIcon from "@images/envelope.png";
import { useSelector } from "react-redux";
import { useAxios } from "@/axiosProvider";
import { resendConfirmationEmail } from "@components/Api/Authentication";

const VerificationEmail = () => {
  const unconfirmedEmail = useSelector((state) => state.user.unconfirmedEmail);
  const apiClient = useAxios();

  const resendConfirmationLink = async () => {
    const payload = { user: { email: unconfirmedEmail } };
    await resendConfirmationEmail(apiClient, payload);
  };

  return (
    <div className="main-layout">
      <Header />
      <div className="root items-center justify-center">
        <span className="auth-header">Email Verification Sent</span>
        <span className="auth-subheader text-center">
          We've sent a verification email to <b>{unconfirmedEmail}</b>. Please
          check your inbox and click on the verification link to confirm your
          account.
        </span>
        <div className="relative inline-block">
          <img
            src={envelopeIcon}
            alt="Envelope"
            className="w-[164px] h-[164px] object-contain"
          />
          <img
            src={verifiedIcon}
            alt="Verified"
            className="absolute bottom-[24px] right-[10px] w-[32px] h-[32px] transform translate-x-1/4 translate-y-1/4"
          />
        </div>
        <div className="auth-footer m-0">
          <div className="auth-footer-text">
            Haven't received the email yet?
          </div>
          <div
            role="button"
            className="auth-footer-btn"
            onClick={resendConfirmationLink}
          >
            Send Again
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationEmail;
