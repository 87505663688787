import React, { useState } from "react";
import ActionButton from "@components/Common/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import CustomTextField from "@components/Common/TextField";
import AuthLayout from "@components/Common/AuthLayout";
import { useSearchParams } from "react-router-dom";
import { useAxios } from "@/axiosProvider";
import { useForm } from "react-hook-form";
import { setErrorMessage } from "@components/Common/FlashMessage";

const ResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("reset_password_token");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const apiClient = useAxios();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = async (data) => {
    if (data.password !== data.passwordConfirmation) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    await apiClient.put("/users/password", {
      user: {
        reset_password_token: resetToken,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
      },
    });
  };

  return (
    <AuthLayout head="reset-pass" title="Reset Password">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          label="Password"
          variant="outlined"
          margin="normal"
          fullWidth
          type={showPassword ? "text" : "password"}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ""}
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
          })}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <CustomTextField
          label="Confirm Password"
          variant="outlined"
          margin="normal"
          fullWidth
          type={showPassword ? "text" : "password"}
          error={!!errors.passwordConfirmation}
          helperText={
            errors.passwordConfirmation
              ? errors.passwordConfirmation.message
              : ""
          }
          {...register("passwordConfirmation", {
            required: "Please confirm your password",
            validate: (value) =>
              value === watch("password") || "Passwords do not match",
          })}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <ActionButton className="auth-button" text="Submit" type="submit" />
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordForm;
