import React, { useState } from "react";
import { Menu as MenuIcon, Add as AddIcon } from "@mui/icons-material";
import dashboardIcon from "@images/dashboard_icon.svg";
import contractsIcon from "@images/contracts_icon.svg";
import reportsIcon from "@images/reports_icon.svg";
import templatesIcon from "@images/templates_icon.svg";

const SidebarComponent = () => {
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(1);

  const handleSidebar = (index) => setSelectedSidebarIndex(index);

  const sidebarItems = [
    { icon: dashboardIcon, text: "Dashboard", action: "/" },
    { icon: contractsIcon, text: "Contracts", action: "/pages/company" },
    { icon: reportsIcon, text: "Reports", action: "/" },
    { icon: templatesIcon, text: "Templates", action: "/pages/templates" },
  ];

  return (
    <React.Fragment>
      <button className="flex p-2 justify-center items-center gap-2 hover:bg-hover-color">
        <MenuIcon className="w-6 h-6" />
      </button>
      <div className="flex justify-center items-center flex-col gap-10">
        <button className="flex justify-center items-center rounded-xl bg-[#E6E8EE] w-6 h-6 p-5 shadow-[0_4px_8px_3px_rgba(0,0,0,0.15),_0_1px_3px_0_rgba(0,0,0,0.30)] mb-2">
          <AddIcon className="text-[#31628D]" />
        </button>
        <div className="flex px-1 pb-1 flex-col items-center gap-3 self-stretch">
          {sidebarItems.map((item, index) => (
            <div
              className="flex px-1 pb-1 flex-col items-center gap-1 self-stretch bg-M3-white cursor-pointer"
              key={index}
            >
              <div
                onClick={() => handleSidebar(index)}
                className={`hover:bg-hover-color px-4 py-1 rounded-[25px] ${
                  selectedSidebarIndex === index ? "bg-secondary-color" : ""
                }`}
              >
                <img src={item.icon} className="w-6 h-6" />
              </div>
              <span className="text-on-surface-variant text-center font-label-medium text-xs font-medium leading-4 tracking-0.125">
                {item.text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SidebarComponent;
