import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unconfirmedEmail: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUnconfirmedEmail: (state, action) => {
      state.unconfirmedEmail = action.payload;
    },
    clearUnconfirmedEmail: (state) => {
      state.unconfirmedEmail = "";
    },
  },
});

export const { setUnconfirmedEmail, clearUnconfirmedEmail } = userSlice.actions;
export default userSlice.reducer;
