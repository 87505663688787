import React from "react";
import ActionButton from "@components/Common/Button";
import CustomTextField from "@components/Common/TextField";
import AuthLayout from "@components/Common/AuthLayout";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { useForm } from "react-hook-form";
import { useAxios } from "@/axiosProvider";
import { useDispatch } from "react-redux";
import { setUnconfirmedEmail } from "@/redux/slices/userSlice";

const SignupComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiClient = useAxios();

  const onSubmit = async (data) => {
    const formattedData = {
      full_name: data.fullName,
      email: data.email,
      organization: {
        name: data.businessName,
      },
      phone: data.phone,
    };

    await apiClient.post("/users", formattedData).then((data) => {
      dispatch(setUnconfirmedEmail(data.email));
    });
  };

  return (
    <AuthLayout
      head="signup"
      title="Sign up"
      subtitle="Create a new account to get started."
      footerText="Already on BAA?"
      footerActionText="Sign in"
      onFooterActionClick={() => navigate("/users/sign_in")}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          label="Full Name"
          variant="outlined"
          margin="normal"
          fullWidth
          {...register("fullName", {
            required: "Full Name is required",
            minLength: {
              value: 2,
              message: "Full Name must be at least 2 characters",
            },
          })}
          error={Boolean(errors.fullName)}
          helperText={errors.fullName?.message}
        />
        <CustomTextField
          label="Business Email"
          variant="outlined"
          margin="normal"
          fullWidth
          {...register("email", {
            required: "Business Email is required",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Enter a valid email address",
            },
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />
        <CustomTextField
          label="Business Name"
          variant="outlined"
          margin="normal"
          fullWidth
          {...register("businessName", {
            required: "Business Name is required",
            minLength: {
              value: 2,
              message: "Business Name must be at least 2 characters",
            },
          })}
          error={Boolean(errors.businessName)}
          helperText={errors.businessName?.message}
        />
        <Grid container spacing={2}>
          <Grid size={2}>
            <CustomTextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="+1"
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
          </Grid>
          <Grid size={10}>
            <CustomTextField
              label="Mobile No."
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
                  message: "Phone number format is not vaild",
                },
              })}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
            />
          </Grid>
        </Grid>
        <ActionButton className="auth-button" text="Signup" type="submit" />
      </form>
    </AuthLayout>
  );
};

export default SignupComponent;
