import React from "react";
import Header from "@components/Common/Header";

const AuthLayout = ({
  title = "",
  subtitle = "",
  footerText = "",
  footerActionText = "",
  onFooterActionClick = () => {},
  children,
  iconAhead = "",
}) => {
  return (
    <div className="main-layout">
      <Header />
      <div className="root">
        <span className="auth-header">{title}</span>
        <div className="flex flex-row gap-x-[4px]">
          {iconAhead && (
            <img src={iconAhead} alt="icon" className="w-18 h-18" />
          )}
          <span className="auth-subheader">{subtitle}</span>
        </div>
        {children}
        <div className="auth-footer">
          <div className="auth-footer-text">{footerText}</div>
          <div
            role="button"
            className="auth-footer-btn"
            onClick={onFooterActionClick}
          >
            {footerActionText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
