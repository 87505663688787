import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import ActionButton from "../Common/Button";
import CustomTextField from "../Common/TextField";
import FormHeaderComponent from "../Common/FormHeader";
import ModalLayoutComponent from "../Common/ModalLayout";
import emptyIcon from "@images/empty_icon.png";
import CommpanyHeadComponent from "./companyHead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getCompanyDetails,
  createNewCompanyProfile,
  updateCompanyProfile,
  getCompaniesList,
} from "@components/Api/CompanyManagment";
import { CompanyAddEditSchema } from "../../helpers/schema";
import useQueryParams from "@components/Common/Hooks/useQueryParams";
import { Skeleton } from "@mui/material";
import { useAxios } from "@/axiosProvider";
import { setCompaniesList } from "@/redux/slices/companySlice";
import { useDispatch } from "react-redux";

let initialStates = {
  id: undefined,
  name: undefined,
  location: undefined,
  website: undefined,
  address: undefined,
  description: undefined,
  full_name: undefined,
  designation: undefined,
  email: undefined,
  phone: undefined,
};

export default function AddEditCompanyProfile({ opt, title, backAction }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(CompanyAddEditSchema),
    defaultValues: initialStates,
  });
  const [logoPreview, setLogoPreview] = useState(emptyIcon);
  const { getQueryParam } = useQueryParams();
  const id = getQueryParam("id");
  const apiClient = useAxios();

  const handleModalState = () => setOpenModal(!openModal);

  useEffect(() => {
    // Fetch company details when editing
    if (id && opt === "edit") {
      fetchCompanyDetails(id);
    } else if (opt === "add") {
      reset(initialStates);
      setLogoPreview(emptyIcon);
    }
  }, [id, opt]);

  const fetchCompanyDetails = async (id) => {
    try {
      const response = await getCompanyDetails(apiClient, id);
      if (response) {
        reset({
          name: response?.data?.name,
          location: response?.data?.location,
          website: response?.data?.website,
          address: response?.data?.address,
          description: response?.data?.description,
          logo: response?.data?.logo_url,
          id: response?.data?.contact_person?.id,
          full_name: response?.data?.contact_person?.full_name,
          designation: response?.data?.contact_person?.designation,
          email: response?.data?.contact_person?.email,
          phone: response?.data?.contact_person?.phone,
        });
      } else {
        reset(initialStates);
      }
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };

  // handle Logo Change
  const handleLogoChange = (file) => {
    setValue("logo", file);
    setLogoPreview(URL.createObjectURL(file));
  };

  // submit handler:
  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    // Append company and contact person details
    const companyFields = [
      "name",
      "location",
      "website",
      "address",
      "description",
    ];
    companyFields.forEach((field) =>
      formData.append(`company[${field}]`, data[field]),
    );
    const contactFields = ["full_name", "designation", "email", "phone"];
    contactFields.forEach((field) =>
      formData.append(
        `company[contact_person_attributes][${field}]`,
        data[field],
      ),
    );
    if (opt !== "add") {
      formData.append("company[contact_person_attributes][id]", watch("id"));
    }
    // Append logo file if it exists
    if (data.logo) formData.append("company[logo]", logoPreview);
    try {
      opt === "add"
        ? await createNewCompanyProfile(apiClient, formData)
        : await updateCompanyProfile(apiClient, getQueryParam("id"), formData);
      // updating companylist state
      const res = await getCompaniesList(apiClient, { search: "" });
      if (res) {
        dispatch(setCompaniesList(res?.data));
        reset(initialStates);
        backAction();
      } else {
        dispatch(setCompaniesList([]));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-M3-white flex h-[968px] pb-6 flex-col items-start gap-6 rounded-l-[12px] flex-1">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div className="relative w-full">
          <FormHeaderComponent
            title={title}
            backAction={backAction}
            btnComponent={
              <div className="flex gap-4">
                <ActionButton
                  className="rounded-[100px] border border-schemes-outline flex flex-col justify-center items-center gap-2 px-6 py-2"
                  text="Cancel"
                  innerTag="span"
                  innerClass="text-primary text-center font-label-large text-[14px] font-medium leading-[20px] tracking-[0.1px]"
                  action={handleModalState}
                />
                <ActionButton
                  type="submit"
                  className="flex flex-col justify-center items-center gap-2 rounded-full bg-primary px-6 py-2"
                  text={opt === "add" ? "Save" : "Save Changes"}
                  innerTag="span"
                  innerClass="text-M3-white text-center font-label-large text-[14px] font-medium leading-[20px] tracking-[0.1px]"
                />
              </div>
            }
          />
          {/* Company Info */}
          <CommpanyHeadComponent
            src={opt === "add" ? logoPreview : watch("logo") || logoPreview}
            name={watch("name") || "Company Name"}
            description={watch("description") || "Description"}
            onLogoChange={handleLogoChange}
          />
        </div>

        {/* Profile Details */}
        <div
          id="profile-form"
          className="flex flex-col gap-y-8 px-14 mt-28 w-full"
        >
          {/* Add/Edit Company Details */}
          <div className="flex flex-col gap-y-6 bg-M3-white shadow-card py-8 px-10 items-start self-stretch">
            <span className="text-on-surface text-[24px] font-normal leading-[32px] font-title-medium">
              {opt === "add" ? "Add" : "Edit"} Company details
            </span>
            <Grid container spacing={3} className="w-full">
              <Grid item size={12} container spacing={2}>
                <Grid size={6}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue={watch("name") || ""}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Company Name"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={6}>
                  <Controller
                    name="location"
                    defaultValue={watch("location") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Location"
                        variant="outlined"
                        error={!!errors.location}
                        helperText={errors.location?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item size={12} container spacing={2}>
                <Grid size={6}>
                  <Controller
                    name="website"
                    defaultValue={watch("website") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Website"
                        variant="outlined"
                        error={!!errors.website}
                        helperText={errors.website?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={6}>
                  <Controller
                    name="address"
                    defaultValue={watch("address") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Address"
                        variant="outlined"
                        error={!!errors.address}
                        helperText={errors.address?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item size={12} container spacing={2}>
                <Grid size={12}>
                  <Controller
                    name="description"
                    defaultValue={watch("description") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Description"
                        variant="outlined"
                        // multiline
                        // rows={4}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>

          {/* Add/Edit Contact Person's Details */}
          <div className="w-full flex flex-col gap-y-6 bg-M3-white shadow-card py-8 px-10 items-start self-stretch">
            <span className="text-on-surface text-[24px] font-normal leading-[32px] font-title-medium">
              {opt === "add" ? "Add" : "Edit"} contact person's details
            </span>
            <Grid container spacing={3} className="w-full">
              <Grid item size={12} container spacing={2}>
                <Grid size={6}>
                  <Controller
                    name="full_name"
                    defaultValue={watch("full_name") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Full Name"
                        variant="outlined"
                        error={!!errors.full_name}
                        helperText={errors.full_name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={6}>
                  <Controller
                    name="designation"
                    defaultValue={watch("designation") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Designation"
                        variant="outlined"
                        error={!!errors.designation}
                        helperText={errors.designation?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item size={12} container spacing={2}>
                <Grid size={6}>
                  <Controller
                    name="email"
                    defaultValue={watch("email") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Email"
                        variant="outlined"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={6}>
                  <Controller
                    name="phone"
                    defaultValue={watch("phone") || ""}
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        fullWidth
                        label="Phone"
                        variant="outlined"
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      {/* Modal */}
      {openModal && (
        <ModalLayoutComponent open={openModal} handleModal={handleModalState}>
          <div className="flex flex-col gap-y-4">
            <span id="transition-modal-title" className="modal-title">
              Unsaved Changes
            </span>
            <p id="transition-modal-description" className="modal-description">
              Canceling will discard unsaved changes to your company profile.
              Continue?
            </p>
          </div>
          <div className="flex justify-end items-end mt-6">
            <div className="flex flex-row gap-x-2">
              <ActionButton
                className="flex justify-start px-3 py-2.5 gap-2 flex-[1_0_0] self-stretch rounded-full hover:bg-hover-color"
                text={"Keep Editing"}
                innerTag="span"
                innerClass="whitespace-nowrap text-primary text-center font-roboto text-[14px] font-medium leading-5 tracking-0.025"
                action={handleModalState}
              />
              <ActionButton
                className="flex justify-start px-4 py-2.5 gap-2 flex-[1_0_0] self-stretch rounded-full hover:bg-hover-color"
                text={"Discard"}
                innerTag="span"
                innerClass="whitespace-nowrap text-primary text-center font-roboto text-[14px] font-medium leading-5 tracking-0.025"
                action={backAction}
              />
            </div>
          </div>
        </ModalLayoutComponent>
      )}
    </div>
  );
}
