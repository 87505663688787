import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import CustomTextField from "../Common/TextField";
import ActionButton from "../Common/Button";
import { getCompaniesList } from "@components/Api/CompanyManagment";
import { setErrorMessage } from "@components/Common/FlashMessage";
import useDebounce from "@components/Common/Hooks/useDebounce";
import useQueryParams from "@components/Common/Hooks/useQueryParams";
import { useNavigate } from "react-router-dom";
import { useAxios } from "@/axiosProvider";
import { useDispatch, useSelector } from "react-redux";
import { setCompaniesList } from "@/redux/slices/companySlice";

let customStyle = {
  maxWidth: "720px",
  borderRadius: "0.5rem",
  border: "none",
};

const CompanyListingComponent = ({ handleCurrentView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companiesList = useSelector((state) => state?.company?.companyList);
  const { getQueryParam, appendQueryParams, removeQueryParam } =
    useQueryParams();
  const [isLoading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(
    getQueryParam("id") || null,
  );
  const debouncedSearchKeyword = useDebounce(searchKeyword, 1000);
  const apiClient = useAxios();
  // company-selection:
  const handleSelectCompany = (id) => {
    setSelectedCompany(id);
    navigate(`?id=${id}`);
  };

  // handle Search
  const handleInputChange = (e) => setSearchKeyword(e.target.value);

  // update params:
  useEffect(() => {
    setSelectedCompany(getQueryParam("id"));
  }, [getQueryParam("id")]);

  // fetch company list data
  useEffect(() => {
    let mount = true;
    if (mount) {
      fetchCompanyListData(debouncedSearchKeyword);
    }
    return () => {
      mount = false;
      removeQueryParam("id");
    };
  }, [debouncedSearchKeyword]);

  // Function to fetch company list data
  const fetchCompanyListData = async (keyword = "") => {
    setLoading(true);
    try {
      const response = await getCompaniesList(apiClient, { search: keyword });
      if (
        response?.data &&
        Array.isArray(response.data) &&
        response.data.length > 0
      ) {
        dispatch(setCompaniesList(response.data));
        appendQueryParams({ id: response?.data[0]?.id });
      } else {
        dispatch(setCompaniesList([]));
      }
    } catch (err) {
      setErrorMessage("Error occurred while fetching the company list");
      dispatch(setCompaniesList([]));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      className={`flex flex-col items-start gap-y-6 p-2 ${!isLoading && "w-full"} h-[968px]`}
    >
      <Box className="flex justify-between h-16 py-2 pr-3 pl-1 items-center gap-1 self-stretch">
        <span className="text-on-surface font-title-medium text-4 font-bold leading-6 tracking-0.0375">
          Contracts
        </span>
        <ActionButton
          className={
            "flex flex-col h-10 py-2.5 px-4 pr-6 justify-center items-center self-stretch gap-2 rounded-full bg-secondary-color"
          }
          text={`+  New Company`}
          innerTag={"span"}
          innerClass={
            "text-on-secondary-container text-center font-label-large text-3.5 font-bold leading-5 tracking-0.025"
          }
          action={() => handleCurrentView("add")}
        />
      </Box>
      <CustomTextField
        fullWidth
        placeholder="Hinted search text"
        slotProps={{
          input: {
            startAdornment: <SearchIcon className="text-gray-400 mr-1" />,
          },
        }}
        sx={customStyle}
        className="flex h-14 items-center gap-1 self-stretch rounded-lg border-none bg-schemes-surface-container"
        onChange={handleInputChange}
        value={searchKeyword}
      />
      <div className="w-full h-[800px] overflow-auto-custom">
        <Box className="flex flex-col justify-center items-center self-stretch bg-scheme-surface  pr-3">
          {isLoading
            ? [...Array(10)].map((_, index) => (
                <div key={index} className="flex flex-col gap-y-1 mb-4">
                  <Skeleton variant="rounded" width={414} height={60} />
                </div>
              ))
            : companiesList &&
              companiesList?.length > 0 &&
              companiesList?.map((company, index) => {
                return (
                  <div
                    key={index + company?.id}
                    onClick={() => handleSelectCompany(company?.id)}
                    className={`flex p-3 px-4 items-center gap-4 self-stretch border-none cursor-pointer ${
                      selectedCompany == company?.id
                        ? "bg-secondary-color"
                        : "hover:bg-hover-color"
                    }`}
                  >
                    <div className="flex items-center w-full flex-row gap-4">
                      <img
                        src={company.logo_url}
                        alt={company.name}
                        className="w-14 h-14 rounded-full"
                      />
                      <Box className="flex flex-col">
                        <span className="text-schemes-on-surface font-body-large text-4 font-normal leading-6 tracking-0.125">
                          {company?.name}
                        </span>
                        <span className="text-schemes-on-surface font-body-medium text-3.5 font-normal leading-5 tracking-0.0625">
                          {company?.description}
                        </span>
                      </Box>
                    </div>
                    <span>{company?.documents_count || 0}</span>
                  </div>
                );
              })}
          {!isLoading && companiesList?.length === 0 && (
            <div>No Companies Found</div>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default CompanyListingComponent;
