import React from "react";

const CommpanyHeadComponent = ({
  src,
  name,
  description,
  onLogoChange = () => {},
  disableUpload = false,
}) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onLogoChange(file);
    }
  };
  return (
    <div className="absolute top-[6.4rem] left-8 w-full flex flex-row gap-x-8 items-center px-6 py-4 z-10">
      <div className="relative">
        <label htmlFor="logo-upload" className="cursor-pointer">
          <img
            src={src}
            className="w-[104px] h-[104px] rounded-full object-cover"
            alt="Company logo"
          />
        </label>
        {!disableUpload && (
          <input
            id="logo-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            name="logo"
          />
        )}
      </div>
      <div className="flex flex-col items-start justify-start">
        <span className="text-on-surface text-center font-label-large text-[32px] font-normal leading-[40px]">
          {name}
        </span>
        <span className="text-on-surface font-body-large text-[16px] font-normal leading-[24px] tracking-[0.5px]">
          {description}
        </span>
      </div>
    </div>
  );
};

export default CommpanyHeadComponent;
