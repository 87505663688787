import * as yup from "yup";

yup.addMethod(yup.array, "unique", function (message, mapper, tree) {
  return this.test("unique", message, function (list) {
    const set = [...new Set(list?.map(mapper))];
    const isUnique = list?.length === set?.length;
    if (isUnique) {
      return true;
    }
    const idx = list?.findIndex((l, i) => mapper(l) !== set[i]);
    if (list[idx][tree.child]?.length === 0) return true;
    return this.createError({
      path: `${tree.parent}.[${idx}].${tree.child}`,
      message: message,
    });
  });
});

export const CompanyAddEditSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required("Company Name is required"),
  location: yup.string(),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter a valid website url",
    )
    .required("Website is required"),
  address: yup.string(),
  description: yup.string(),
  full_name: yup.string(),
  designation: yup.string(),
  email: yup.string().email("Enter a valid email"),
  phone: yup.string(),
  // .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
  // .required("Phone is required"),
});
