import React from "react";

export default function ActionButton({
  className = "",
  text = "",
  innerTag = "",
  innerClass = "",
  src = "",
  alt = "",
  action = () => {},
  type = "button",
  ...props
}) {
  let content;
  switch (innerTag) {
    case "span":
      content = <span className={innerClass}>{text}</span>;
      break;
    case "image":
      content = <img src={src} alt={alt} className={innerClass} />;
      break;
    default:
      content = text;
      break;
  }

  return (
    <button type={type} onClick={action} className={className} {...props}>
      {content}
    </button>
  );
}
