import React, { useEffect, useState } from "react";
import ActionButton from "../Common/Button";
import FormHeaderComponent from "../Common/FormHeader";
import ModalLayoutComponent from "../Common/ModalLayout";
import Stack from "@mui/material/Stack";
import { contactPersonDetails } from "../../helpers/data";
import emptyIcon from "@images/empty_icon.png";
import {
  getCompaniesList,
  getCompanyDetails,
  deleteCompanyProfile,
} from "@components/Api/CompanyManagment";
import useQueryParams from "@components/Common/Hooks/useQueryParams";
import CommpanyHeadComponent from "./companyHead";
import { Skeleton } from "@mui/material";
import { useAxios } from "@/axiosProvider";
import { setCompaniesList } from "@/redux/slices/companySlice";
import { useDispatch } from "react-redux";

export default function CompanyDetailsComponent({
  opt,
  title,
  backAction,
  handleCurrentView,
}) {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const { getQueryParam, updateQueryParams } = useQueryParams();
  const [isLoading, setLoading] = useState(false);
  const id = getQueryParam("id");
  const apiClient = useAxios();

  const handleDeleteModalState = () => setOpenDeleteModal(!openDeleteModal);

  useEffect(() => {
    id && fetchCompanyDetails(id);
  }, [id]);

  const fetchCompanyDetails = async (id) => {
    try {
      const response = await getCompanyDetails(apiClient, id);
      if (response) {
        setCompanyDetails(response.data);
      } else {
        setCompanyDetails([]);
      }
    } catch (err) {
      console.error("Fetch error:", err);
    } finally {
      setLoading(false);
    }
  };

  // delet specific company:
  const removeCompany = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await deleteCompanyProfile(apiClient, id);
      if (response) {
        const res = await getCompaniesList(apiClient, { search: "" });
        if (res) {
          dispatch(setCompaniesList(res?.data));
          updateQueryParams({ id: res?.data[0]?.id });
          backAction();
        }
      }
    } catch (err) {
      console.error("Fetch error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-M3-white flex h-[968px] pb-6 flex-col items-start gap-6 rounded-l-[12px] flex-1">
      {/* Header */}
      <div className="relative w-full">
        <FormHeaderComponent
          title={title}
          backAction={backAction}
          btnComponent={
            <div className="flex gap-4">
              <ActionButton
                className="rounded-[100px] border border-schemes-outline flex flex-col justify-center items-center gap-2 px-6 py-2"
                text="Delete"
                innerTag="span"
                innerClass="text-primary text-center font-label-large text-[14px] font-medium leading-[20px] tracking-[0.1px]"
                action={handleDeleteModalState}
              />
              <ActionButton
                className="flex flex-col justify-center items-center gap-2 rounded-full bg-primary px-6 py-2"
                text={"Edit"}
                innerTag="span"
                innerClass="text-M3-white text-center font-label-large text-[14px] font-medium leading-[20px] tracking-[0.1px]"
                action={() => handleCurrentView("edit")}
              />
            </div>
          }
        />

        {/* Company Info */}
        <CommpanyHeadComponent
          src={companyDetails?.logo_url || emptyIcon}
          name={companyDetails?.name}
          description={companyDetails?.description}
          disableUpload={true}
        />
      </div>

      <div className="flex flex-col gap-y-8 px-14 mt-28 w-full">
        <Stack spacing={3}>
          <DetailsCard
            title="Company Details"
            details={{
              Location: companyDetails?.location || "-",
              Website: companyDetails?.website || "-",
              Address: companyDetails?.address || "-",
            }}
          />
          <DetailsCard
            title="Contact Person"
            details={{
              "Full Name": companyDetails?.contact_person?.full_name || "-",
              Designation: companyDetails?.contact_person?.designation || "-",
              Email: companyDetails?.contact_person?.email || "-",
              Mobile: companyDetails?.contact_person?.phone || "-",
            }}
          />
        </Stack>
      </div>

      {/* Delete Modal */}
      {openDeleteModal && (
        <ModalLayoutComponent
          open={openDeleteModal}
          handleModal={handleDeleteModalState}
        >
          <div className="flex flex-col gap-y-4">
            <span id="transition-modal-title" className="modal-title">
              Delete Company
            </span>
            <p id="transition-modal-description" className="modal-description">
              Are you sure you want to delete this company ?
            </p>
          </div>
          <div className="flex justify-end items-end mt-6">
            <div className="flex flex-row gap-x-2">
              <ActionButton
                className="flex justify-start px-3 py-2.5 gap-2 flex-[1_0_0] self-stretch rounded-full hover:bg-hover-color"
                text={"Yes"}
                innerTag="span"
                innerClass="whitespace-nowrap text-primary text-center font-roboto text-[14px] font-medium leading-5 tracking-0.025"
                action={removeCompany}
              />
              <ActionButton
                className="flex justify-start px-4 py-2.5 gap-2 flex-[1_0_0] self-stretch rounded-full hover:bg-hover-color"
                text={"No"}
                innerTag="span"
                innerClass="whitespace-nowrap text-primary text-center font-roboto text-[14px] font-medium leading-5 tracking-0.025"
                action={handleDeleteModalState}
              />
            </div>
          </div>
        </ModalLayoutComponent>
      )}
    </div>
  );
}

const DetailsCard = ({ title, details }) => {
  return (
    <div className="flex flex-col items-start p-4 gap-8 self-stretch rounded-lg bg-M3-white shadow-md">
      <span className="text-center text-on-surface font-title-large text-[22px] font-normal leading-[28px]">
        {title}
      </span>
      <Stack direction="row" spacing={5}>
        {details !== null ? (
          Object.keys(details).map((key, index) => (
            <div key={index} className="flex flex-col gap-y-1">
              <span className="text-on-surface font-body-large text-[16px] font-normal leading-[24px] tracking-[0.5px]">
                {key}:
              </span>
              <span className="text-on-surface font-body-large text-[16px] font-bold leading-[24px] tracking-[0.5px]">
                {details[key]}
              </span>
            </div>
          ))
        ) : (
          <span>No details available.</span>
        )}
      </Stack>
    </div>
  );
};
