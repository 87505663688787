// utils/queryParamsUtils.js
import { useLocation, useNavigate } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const updateQueryParams = (updates = {}, removeKeys = []) => {
    const queryParams = new URLSearchParams(location.search);

    // Apply updates to queryParams
    Object.keys(updates).forEach((key) => {
      if (updates[key] === null) {
        // Remove key if value is null
        if (queryParams.has(key)) {
          queryParams.delete(key);
        }
      } else {
        // Update or set new key-value pair
        queryParams.set(key, updates[key]);
      }
    });

    // Remove specified keys
    removeKeys.forEach((key) => {
      if (queryParams.has(key)) {
        queryParams.delete(key);
      }
    });

    navigate(
      { pathname: location.pathname, search: queryParams.toString() },
      { replace: true },
    );
  };

  const appendQueryParams = (params) => {
    const queryParams = new URLSearchParams(location.search);

    // Append new parameters only if they don't already exist
    Object.keys(params).forEach((key) => {
      if (!queryParams.has(key)) {
        queryParams.append(key, params[key]);
      }
    });

    navigate(
      { pathname: location.pathname, search: queryParams.toString() },
      { replace: true },
    );
  };

  const hasQueryParam = (key) => {
    return getQueryParams().has(key);
  };

  const getQueryParam = (key) => {
    return getQueryParams().get(key);
  };

  const removeQueryParam = (key) => {
    if (hasQueryParam(key)) {
      updateQueryParams({}, [key]);
    }
  };

  return {
    updateQueryParams,
    appendQueryParams,
    hasQueryParam,
    getQueryParam,
    removeQueryParam,
  };
};

export default useQueryParams;
