import React, { useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DetailedDocumentsView from "./detailedDocuments";
import SidebarComponent from "../Common/Sidebar";
import CompanyListingComponent from "./companyListing";
import AddEditCompanyProfile from "./addEditCompany";
import CompanyDetailsComponent from "./companyDetails";

const theme = createTheme();

const CompanyManagementInterface = () => {
  const [currentView, setCurrentView] = useState("");
  const handleCurrentView = (view) => setCurrentView(view);
  return (
    <ThemeProvider theme={theme}>
      <Box className="flex flex-col bg-scheme-surface">
        <Grid container spacing={1} className="flex-grow overflow-hidden">
          <Grid
            item
            size={0.7}
            className="flex w-20 py-11 pb-14 flex-col items-center gap-2 bg-M3-white"
          >
            <SidebarComponent />
          </Grid>
          <Grid
            item
            size={3.5}
            className="flex justify-center bg-scheme-surface pt-14 px-3"
          >
            <CompanyListingComponent handleCurrentView={handleCurrentView} />
          </Grid>
          <Grid item size={7.8} className="pt-14 pr-14">
            {(() => {
              switch (currentView) {
                case "add":
                  return (
                    <AddEditCompanyProfile
                      opt={currentView}
                      title={"Create Company Profile"}
                      backAction={() => setCurrentView("")}
                    />
                  );
                case "edit":
                  return (
                    <AddEditCompanyProfile
                      opt={currentView}
                      title={"Edit Company Profile"}
                      backAction={() => setCurrentView("")}
                    />
                  );
                case "details":
                  return (
                    <CompanyDetailsComponent
                      opt={currentView}
                      title={"Company Profile"}
                      backAction={() => setCurrentView("")}
                      handleCurrentView={handleCurrentView}
                    />
                  );
                default:
                  return (
                    <DetailedDocumentsView
                      handleCurrentView={handleCurrentView}
                    />
                  );
              }
            })()}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default CompanyManagementInterface;
