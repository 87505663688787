import { configureStore } from "@reduxjs/toolkit";
import userReducer from "@/redux/slices/userSlice";
import companyReducer from "@/redux/slices/companySlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    company: companyReducer,
  },
});

export default store;
