import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ActionButton from "../Common/Button";
import companyLogo1 from "@images/company_logo_1.png";
import templateImg from "@images/template.png";
import settingsIcon from "@images/settings_icon.svg";
import listIcon from "@images/list_icon.svg";
import gridIcon from "@images/grid_icon.svg";
import contractIcon from "@images/contract_icon.png";
import useHorizontalScroll from "@components/Common/Hooks/useHorizontalScroll";
import { useSelector } from "react-redux";
import useQueryParams from "@components/Common/Hooks/useQueryParams";
import emptyIcon from "@images/empty_icon.png";

const DetailedDocumentsView = ({ handleCurrentView }) => {
  const companiesList = useSelector((state) => state.company?.companyList);
  const [activeView, setActiveView] = useState("grid");
  const {
    scrollRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseUp,
    handleMouseMove,
  } = useHorizontalScroll();
  const { getQueryParam } = useQueryParams();
  const id = getQueryParam("id");

  const handleActiveView = (view) => {
    setActiveView(view);
  };

  const getSelectedCompany =
    companiesList &&
    companiesList?.length > 0 &&
    companiesList?.find((it) => it?.id == id);

  return (
    <Box className="flex flex-col flex-[1_0_0] rounded-l-lg bg-schemes-surface-container-lowest h-[968px] overflow-auto-list">
      <div className="flex flex-col gap-y-4 p-6">
        <div className="flex justify-between w-full">
          <div className="flex flex-row gap-x-2">
            <img
              src={getSelectedCompany?.logo_url || emptyIcon}
              alt={getSelectedCompany?.name || "image"}
              className="w-12 h-12 rounded-full cursor-pointer"
            />
            <span className="flex items-center text-on-surface font-roboto text-2xl font-normal leading-8">
              {getSelectedCompany?.name || ""}
            </span>
          </div>
          <img
            src={settingsIcon}
            alt={"settings"}
            className="items self-center w-6 h-6 cursor-pointer"
            onClick={() => handleCurrentView("details")}
          />
        </div>
        <div className="flex flex-col gap-y-4 w-full">
          <Box className="flex justify-between h-16 py-2 items-center self-stretch pr-7.5">
            <span className="text-on-surface font-roboto text-[22px] font-normal leading-7">
              Templates
            </span>
            <ActionButton
              className={
                "flex h-10 px-6 py-2.5 justify-center items-center gap-2 self-stretch rounded-full bg-primary"
              }
              text={`+  New Contract`}
              innerTag={"span"}
              innerClass={
                "text-on-primary text-center font-label-large text-[14px] font-medium leading-5 tracking-0.025"
              }
            />
          </Box>
          {/* Template list section */}
          <div
            ref={scrollRef}
            className="overflow-x-auto flex px-6 scrollbar-hide cursor-grab active:cursor-grabbing"
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <div className="flex flex-nowrap gap-x-6">
              {[...Array(117)].map((_, index) => (
                <div key={index} className="flex-none">
                  <div className="flex flex-col items-center justify-center gap-y-2">
                    <img
                      src={templateImg}
                      alt="template"
                      className="w-24 h-24 shrink-0"
                    />
                    <span className="text-center overflow-hidden text-ellipsis self-stretch custom-clamp text-on-surface font-label-large text-[14px] font-semibold leading-5 tracking-0.0375">
                      Template-{index + 1}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ActionButton
          className={
            "flex justify-start w-44 px-4 py-2.5 gap-2 flex-[1_0_0] self-stretch rounded-full hover:bg-hover-color"
          }
          text={`+  See More Templates`}
          innerTag={"span"}
          innerClass={
            "whitespace-nowrap text-primary text-center font-roboto text-[14px] font-medium leading-5 tracking-0.025"
          }
        />
      </div>
      {/* Contracts Section */}
      <div className="flex flex-col gap-y-4 p-6">
        <div className="flex flex-col gap-y-4 w-full">
          <Box className="flex justify-between h-16 py-2 items-center self-stretch">
            <span className="text-on-surface font-roboto text-[22px] font-normal leading-7">
              Contracts
            </span>
            <div className="flex flex-row gap-x-0.5 pr-2.5">
              <ActionButton
                className={`flex p-2 flex-col justify-center items-center gap-2.5 rounded-md ${
                  activeView === "list"
                    ? "bg-secondary-color"
                    : "bg-hover-color"
                }`}
                innerTag={"image"}
                innerClass={"w-6 h-6"}
                src={listIcon}
                alt={"list"}
                action={() => handleActiveView("list")}
              />
              <ActionButton
                className={`flex p-2 flex-col justify-center items-center gap-2.5 rounded-md ${
                  activeView === "grid"
                    ? "bg-secondary-color"
                    : "bg-hover-color"
                }`}
                innerTag={"image"}
                innerClass={"w-6 h-6"}
                src={gridIcon}
                alt={"grid"}
                action={() => handleActiveView("grid")}
              />
            </div>
          </Box>
          {activeView === "grid" ? (
            <Grid container spacing={5}>
              {[...Array(12)].map((_, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="flex"
                >
                  <div className="flex flex-col justify-center items-center flex-[1_0_0] self-stretch rounded-md border border-outline-variant">
                    <img
                      src={contractIcon}
                      alt="template"
                      className="w-47 h-52"
                    />
                    <div className="flex p-2 flex-col items-start gap-1.5 self-stretch bg-scheme-surface">
                      <span className="text-xs font-normal leading-0.775 tracking-0.016 text-body-large">
                        Title
                      </span>
                      <span className="text-[9px] font-normal leading-0.65 tracking-0.0125 text-on-surface font-body-medium">
                        Draft
                      </span>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container rowSpacing={2}>
              {[...Array(12)].map((_, index) => (
                <Grid key={index} size={12}>
                  <div className="flex flex-row p-2 px-4 items-center gap-4 flex-1 align-self-stretch bg-scheme-surface w-full">
                    <div className="flex flex-row gap-x-4">
                      <img src={contractIcon} className="w-14 h-14" />
                      <div className="flex flex-col gap-y-1">
                        <span className="text-[#181C20] font-body-large text-4 font-normal leading-6 tracking-[0.5px]">
                          Title
                        </span>
                        <span className="text-[#42474E] overflow-hidden text-ellipsis whitespace-nowrap font-body-medium text-[14px] font-normal leading-[20px] tracking-[0.25px]">
                          Pending Signature
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </div>
    </Box>
  );
};

export default DetailedDocumentsView;
