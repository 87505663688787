import React from "react";
import { Button } from "@mui/material";
import logo from "@images/logo.svg";

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="App Logo" className="logo-img" />
        <h3 className="logo-head">Sign BAA</h3>
      </div>
      <Button variant="text" className="help-button">
        <span className="help-btn-text">Help</span>
      </Button>
    </header>
  );
};

export default Header;
