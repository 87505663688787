import * as React from "react";
import PropTypes from "prop-types";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import Fade from "@mui/material/Fade";
import useDisableBodyScroll from "./Hooks/useDisableBodyScroll";

export default function ModalLayoutComponent({ open, handleModal, children }) {
  useDisableBodyScroll(open); // prevent scrollling, when opened
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleModal}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>{children}</ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.displayName = "Backdrop";

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    position: relative;
    gap: 8px;
    overflow: hidden;
    display: flex;
    width: 312px;
    min-width: 280px;
    max-width: 560px;
    flex-direction: column;
    border-radius: var(--Corner-Extra-large, 28px);
    background: var(--Schemes-Surface-Container-High, #e6e8ee);

    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;

    & .modal-title {
      margin: 0;
      color: var(--Schemes-On-Surface, #181c20);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      color: var(--Schemes-On-Surface-Variant, #42474e);
      font-family: var(--Body-Medium-Font, Roboto);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  `,
);
