import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { IconButton, InputAdornment } from "@mui/material";
import CustomTextField from "@components/Common/TextField";
import AuthLayout from "@components/Common/AuthLayout";
import ActionButton from "@components/Common/Button";
import { useAxios } from "@/axiosProvider";
import checkCircle from "@images/check_circle.svg";
import { useDispatch } from "react-redux";
import { setUnconfirmedEmail } from "@/redux/slices/userSlice";
import { updatePassword } from "@components/Api/Authentication";

const InitialPasswordSetup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const apiClient = useAxios();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data) => {
    const payload = {
      user: {
        password: data.password,
        password_confirmation: data.confirmPassword,
      },
    };
    await updatePassword(apiClient, payload).then((response) => {
      dispatch(setUnconfirmedEmail(""));
    });
  };

  return (
    <AuthLayout
      head="initial-pass"
      title="Password Setup"
      subtitle="Email verified. Create your password now."
      iconAhead={checkCircle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          label="Password"
          variant="outlined"
          margin="normal"
          fullWidth
          type={showPassword ? "text" : "password"}
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <CustomTextField
          label="Confirm Password"
          variant="outlined"
          margin="normal"
          fullWidth
          type={showConfirmPassword ? "text" : "password"}
          {...register("confirmPassword", {
            required: "Please confirm your password",
            validate: (value) =>
              value === watch("password") || "Passwords do not match",
          })}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <ActionButton className="auth-button" text="Submit" type="submit" />
      </form>
    </AuthLayout>
  );
};

export default InitialPasswordSetup;
