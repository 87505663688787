import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment } from "@mui/material";

import ActionButton from "@components/Common/Button";
import CustomTextField from "@components/Common/TextField";
import AuthLayout from "@components/Common/AuthLayout";
import { useAxios } from "@/axiosProvider";
import { loginUser } from "@components/Api/Authentication";
const LoginComponent = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const apiClient = useAxios();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data) => {
    const payload = {
      user: {
        email: data.email,
        password: data.password,
      },
    };
    await loginUser(apiClient, payload);
  };

  return (
    <AuthLayout
      head="signin"
      title="Sign in"
      subtitle="Enter your email and password to log in."
      footerText="New to Sign BAA?"
      footerActionText="Sign up"
      onFooterActionClick={() => navigate("/users/sign_up")}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          {...register("email", {
            required: "Email is required",
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />
        <CustomTextField
          label="Password"
          variant="outlined"
          margin="normal"
          fullWidth
          {...register("password", {
            required: "Password is required",
          })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          type={showPassword ? "text" : "password"}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <Button
          onClick={() => navigate("/users/password/new")}
          variant="text"
          className="forgot-pass-btn"
        >
          <span className="forgot-btn-text">Forgot Password ?</span>
        </Button>
        <ActionButton type="submit" className="auth-button" text="Login" />
      </form>
    </AuthLayout>
  );
};

export default LoginComponent;
