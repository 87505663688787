import React from "react";
import arrowBack from "@images/arrow_back.svg";

export default function FormHeaderComponent({
  title,
  btnComponent,
  backAction = () => {},
}) {
  return (
    <div className="bg-[#ECEEF4] flex h-[133px] py-4 px-6 flex-row justify-between items-center gap-2.5 w-full">
      <div className="flex flex-row gap-x-2">
        <img
          onClick={backAction}
          src={arrowBack}
          className="w-6 h-6 mt-[5px] cursor-pointer"
        />
        <span className="text-[24px] font-normal leading-[32px] text-on-surface flex-1">
          {title}
        </span>
      </div>
      {btnComponent}
    </div>
  );
}
