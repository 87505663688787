import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#31628D",
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#31628D",
  },
}));

export default CustomTextField;
