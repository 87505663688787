import { SnackbarProvider, enqueueSnackbar } from "notistack";
import React from "react";

const setSuccessMessage = (message) => {
  enqueueSnackbar(message, { variant: "success" });
};

const setErrorMessage = (message) => {
  enqueueSnackbar(message, { variant: "error" });
};

const FlashMessage = ({ children, ...props }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    >
      {children}
    </SnackbarProvider>
  );
};

export { FlashMessage, setSuccessMessage, setErrorMessage };
