import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "@/axiosProvider";

import ActionButton from "@components/Common/Button";
import CustomTextField from "@components/Common/TextField";
import AuthLayout from "@components/Common/AuthLayout";

const ResetPasswordComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const apiClient = useAxios();

  const handleSubmit = async (event) => {
    event.preventDefault();

    await apiClient.post("/users/password", {
      user: { email: email },
    });
  };

  return (
    <AuthLayout
      head="resetPass"
      title="Reset Password"
      subtitle="Enter your email address to receive a password reset link."
      footerText="New to Sign BAA?"
      footerActionText="Sign up"
      onFooterActionClick={() => navigate("/users/sign_up")}
    >
      <form onSubmit={handleSubmit}>
        <CustomTextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <ActionButton
          type="submit"
          className="auth-button"
          text="Send password reset link"
        />
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordComponent;
