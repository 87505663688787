import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import Home from "@components/Home";
import LoginComponent from "@components/Authentication/Login";
import SignupComponent from "@components/Authentication/Signup";
import ResetPasswordLink from "@components/Authentication/ResetPassword/New";
import ResetPasswordForm from "@components/Authentication/ResetPassword/Form";
import VerificationEmail from "@components/Authentication/VerificationEmail";
import InitialPasswordSetup from "@components/Authentication/InitialPassword";
import CompanyManagementInterface from "@components/CompanyManagement";
import {
  setSuccessMessage,
  setErrorMessage,
  FlashMessage,
} from "@components/Common/FlashMessage";
import store from "@/redux/store";
import { AxiosProvider } from "@/axiosProvider";

const App = () => {
  useEffect(() => {
    const successMessage = document
      .querySelector('meta[name="flash-notice"]')
      .getAttribute("content");
    if (successMessage) {
      setSuccessMessage(successMessage);
    }

    const errorMessage = document
      .querySelector('meta[name="flash-error"]')
      .getAttribute("content");
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
  }, []);

  return (
    <Router>
      <AxiosProvider>
        <Routes>
          <Route path="/users/sign_in" element={<LoginComponent />} />
          <Route path="/users/sign_up" element={<SignupComponent />} />
          <Route
            path="/users/set_password"
            element={<InitialPasswordSetup />}
          />
          <Route path="/users/password/new" element={<ResetPasswordLink />} />
          <Route path="/users/password/edit" element={<ResetPasswordForm />} />
          <Route path="/users/verify_email" element={<VerificationEmail />} />
          <Route
            path="/pages/company"
            element={<CompanyManagementInterface />}
          />
          <Route path="/" element={<CompanyManagementInterface />} />
          <Route path="/pages/home" element={<Home />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </AxiosProvider>
    </Router>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <FlashMessage>
        <App />
      </FlashMessage>
    </Provider>,
  );
});
