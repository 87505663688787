export const getCompaniesList = (apiClient, payload) => {
  const params = {
    ...(payload.search && { search: payload.search }),
  };
  return apiClient.get(`/companies`, {
    params: params,
  });
};

export const getCompanyDetails = (apiClient, id) => {
  return apiClient.get(`/companies/${id}`);
};

export const createNewCompanyProfile = (apiClient, reqBody) => {
  return apiClient.post(`/companies`, reqBody);
};

export const updateCompanyProfile = (apiClient, id, reqBody) => {
  return apiClient.patch(`/companies/${id}`, reqBody);
};

export const deleteCompanyProfile = (apiClient, id) => {
  return apiClient.delete(`/companies/${id}`);
};
