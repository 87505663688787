import React, { useEffect } from "react";

const useDisableBodyScroll = (open) => {
  useEffect(() => {
    if (open) {
      document.body.style.height = "100vh";
    } else {
      document.body.style.height = "";
    }
    return () => {
      document.body.style.height = "";
    };
  }, [open]);
};

export default useDisableBodyScroll;
