export const updatePassword = (apiClient, payload) => {
  return apiClient.put(`/users/update_password`, payload);
};

export const loginUser = (apiClient, payload) => {
  return apiClient.post("/users/sign_in", payload);
};

export const resendConfirmationEmail = (apiClient, payload) => {
  return apiClient.post("/users/confirmation", payload);
};
