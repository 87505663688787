import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyList: [],
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompaniesList: (state, action) => {
      state.companyList = action.payload;
    },
  },
});

export const { setCompaniesList } = companySlice.actions;
export default companySlice.reducer;
