import axios from "axios";
import {
  setErrorMessage,
  setSuccessMessage,
} from "@components/Common/FlashMessage";
import { useNavigate } from "react-router-dom";
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

const AxiosContext = createContext();

const csrfToken = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

const apiClient = axios.create({
  headers: {
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

export const AxiosProvider = ({ children }) => {
  const navigate = useNavigate();
  const [interceptorReady, setInterceptorReady] = useState(false);

  useEffect(() => {
    // Attach interceptors only once
    const handleResponse = (response) => {
      if (response.status >= 200 && response.status < 300) {
        if (response.data.message) {
          setSuccessMessage(response.data.message);
        }

        if (response.data.redirect_url) {
          navigate(response.data.redirect_url);
          return;
        }
      }
      return response.data; // Unwrap the response data to simplify usage in components
    };

    const handleError = (error) => {
      if (error.response) {
        setErrorMessage(
          error.response.data.message || `Error: ${error.response.statusText}`,
        );
        logErrorToService(error);
      } else if (error.request) {
        setErrorMessage("No response received from the server");
        logErrorToService(error);
      } else {
        setErrorMessage(error.message || "Error setting up request");
        logErrorToService(error);
      }

      return Promise.reject(error); // Reject the promise to handle it later if needed
    };

    const logErrorToService = (error) => {
      console.error(error);
    };

    // Attach interceptors
    const responseInterceptor = apiClient.interceptors.response.use(
      handleResponse,
      handleError,
    );

    // Set interceptor as ready
    setInterceptorReady(true);

    // Cleanup on unmount to avoid duplicate interceptors
    return () => {
      apiClient.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  // Use useMemo to ensure apiClient is not recreated on every render
  const value = useMemo(() => apiClient, []);

  // Render children only when interceptor is ready
  if (!interceptorReady) {
    return <div>Loading...</div>; // You can customize this loading state
  }

  return (
    <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
  );
};

// Custom hook to use Axios instance
export const useAxios = () => {
  return useContext(AxiosContext);
};
